import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { ReactComponent as Megaphone } from '../../assents/img/megaphone.svg';
import Cookies from '../../assents/pdf/Cookies.pdf'
import Integritetspolicy from '../../assents/pdf/Integritetspolicy.pdf'

import { ReactComponent as Facebook } from '../../assents/img/facebook.svg';
import { ReactComponent as Linkedin } from '../../assents/img/linkedin.svg';
import { ReactComponent as Instagram } from '../../assents/img/inst.svg';

class MobileFooter extends Component {
  render() {
    const { onShowCookie, handleModal } = this.props
    return (
        <div className='footer'>
          <div className='row'>
            <div className="col-xs-7">
              <nav>
                <ul>
                  <li>
                    <Link className="footer-link" onClick={handleModal} to={{javascript: void(0)}}>BLI EN FOODFIGHTER</Link>
                  </li>
                  <li>
                    <Link className="footer-link" to='/blog'>BLOGGEN</Link>
                  </li>
                  <li>
                    <Link className="footer-link" to='/about'>OM OSS</Link>
                  </li>
                  <li>
                    <Link className="footer-link" to='/news'>NYHETER</Link>
                  </li>
                  <li>
                    <a className="footer-link" href="mailto:info@foodfighters.se">KONTAKT</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-xs-5 footer-logo-wrapper">
              <div className="footer-logo">
                <Megaphone/>
              </div>
            </div>
          </div>
          <ul className="text-right margin-top-20 aboutus-links">
            <li>
              <span className="footer-link">FöLJ OSS:</span>
            </li>
          </ul>
          <ul className="text-right social">
            <li>
              <span>
                 <a href='https://www.instagram.com/we_are_foodfighters/' target="_blank" rel="noopener noreferrer">
                   <Instagram/>
                </a>
                <a href='https://www.facebook.com/foodfighters.se/' target="_blank" rel="noopener noreferrer">
                  <Facebook/>
                </a>
                <a href='https://www.linkedin.com/company/40656753' target="_blank" className="linkedin-icon" rel="noopener noreferrer">
                   <Linkedin/>
                </a>
              </span>
            </li>
          </ul>
          <div className='row footer-description'>
            <div>
              <span onClick={ () => { onShowCookie() } }>
                <a className='policy' href={Cookies} target='_blank' rel='noopener noreferrer'>COOKIES</a>
              </span><br/>
              <a className='policy' href={Integritetspolicy} target='_blank' rel='noopener noreferrer'>PERSONUPPGIFTER</a>
            </div>
            <div>
              @ Foodfighters AB 2020
            </div>
          </div>
        </div>
    )
  }
}

export default MobileFooter;