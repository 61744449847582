import * as constants from '../constants/constants'

const initialState = {
	access_token: '',
	email: '',
	rememberMe: ''
};

export default (state = initialState, action) => {
	const {payload} = action;
	switch (action.type) {
		case constants.LOGIN:
			return Object.assign({}, state, {
				...state,
				access_token: payload.token,
				email: payload.email,
				rememberMe: payload.rememberMe,
			});
		case constants.LOGOUT:
			return Object.assign({}, state, {
				...state,
				access_token: ''
			});
		default:
			return state;
	}
};