export const GET_PRODUCTS = 'GET_PRODUCTS';
export const SORT_BY_PRICE = 'SORT_BY_PRICE';
export const SORT_BY_ENVIRONMENT = 'SORT_BY_ENVIRONMENT';
export const SORT_BY_HEALTH = 'SORT_BY_HEALTH';
export const SORT_BY_WORKING_CONDITIONS = 'SORT_BY_WORKING_CONDITIONS';
export const SORT_BY_TOTAL_SCORE = 'SORT_BY_TOTAL_SCORE';
export const SORT_BY_DEFAULT = 'SORT_BY_DEFAULT';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const HANDLE_MODAL = 'HANDLE_MODAL';
export const SAVE_FAMILYINFO = 'SAVE_FAMILYINFO';
export const GET_GENDERS = 'GET_GENDERS'
export const GET_USER = 'GET_USER'
export const CLEAR_USER = 'CLEAR_USER'
export const SET_BLOGS = 'SET_BLOGS'
export const SET_BLOG_ERROR = 'SET_BLOG_ERROR'
export const SET_NEWS = 'SET_NEWS'
export const SET_NEWS_ERROR = 'SET_NEWS_ERROR'
export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const TOGGLE_MODAL_RESULTS = 'TOGGLE_MODAL_RESULTS'
export const SEARCH_BLOG = 'SEARCH_BLOG'

