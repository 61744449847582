import axiosCall from "../helper";
import {SubmissionError} from "redux-form";
import { handleModal } from '../actions/signUp'
import { toggleModalResults } from '../actions/toggleModalResults'

export const createSignUp = (values, dispatch, props) => {
	return (dispatch) => {
    Object.keys(values).map(p => 
	{
		if(p === 'zip_code'){
			values[p] = values[p].replace(/\s/g, '');
		}
		values[p] = typeof values[p] == 'string' ? values[p].trim() : values[p]
	});
		return axiosCall.post('/api/v2/subscribers/register', values)
			.then(response => {
				dispatch(handleModal());
				dispatch(toggleModalResults())
				document.getElementById('headerWrapper').scrollIntoView({ behavior: 'smooth' })
			})
			.catch(error => {
        let errors = error.response.data.errors
				if (error.response.status === 422) {
					throw new SubmissionError({
            email: (errors.email && errors.email[0]) === 'The email has already been taken.' ?
              "Den här e-postadressen används redan" : errors.email,
            first_name: (errors.first_name && errors.first_name[0]) === 'The first name field is required.' ?
              "Förnamnsfältet krävs" : errors.first_name,
            last_name: (errors.last_name && errors.last_name[0]) === 'The second name field is required.' ?
              "Det andra namnfältet krävs" : errors.second_name,
            age: errors.age,
            zip_code: errors.zip_code
					})
				}
			})
	}
};

