import React, {Component} from 'react';
import JSCookie from 'js-cookie'
import './cookie.scss'
import Cookies from "../../assents/pdf/Cookies.pdf";

class Cookie extends Component {
  constructor(props) {
    super(props);
    this.isCookieAccepted = null;
  };

  componentDidMount () {
    this.isCookieAccepted = JSCookie.get('isCookieAccepted');
  };

  accept = () => {
    JSCookie.set('isCookieAccepted', true);
    this.isCookieAccepted = true
    this.forceUpdate()
  };

  render () {
    return (
      <div className='cookie' style={{ display: this.isCookieAccepted ? 'none': '' }}>
        <div className="content-wrapper">
            FoodFighters använder cookies för att förbättra och förenkla dina besök hos oss. 
            <span>
                <a href={Cookies} target='_blank' rel='noopener noreferrer'>Läs mer</a>
            </span>
            <span onClick={this.accept} className="ok">
                HELT OK!
            </span>
        </div>
      </div>
    )
  };
}

export default Cookie;