import {connect} from 'react-redux'
import SignUp from '../components/SignUp/SignUp'
import { handleModal } from '../actions/signUp'
import { closeModal } from '../actions/closeSignUp'
import { createSignUp } from '../actions/createSignUp'

import { toggleModalResults } from '../actions/toggleModalResults'
import {bindActionCreators} from 'redux'

const mapStateToProps = (state) => {
	return {
		showModalSignUp: state.SignUp.showModalSignUp,
		showModalResult: state.SignUp.showModalResult
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		handleModal: bindActionCreators(handleModal, dispatch),
		toggleModalResults: bindActionCreators(toggleModalResults, dispatch),
		closeModal: bindActionCreators(closeModal, dispatch),
		createSignUp: bindActionCreators(createSignUp, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
