import {connect} from 'react-redux'
import Footer from '../components/Footer/Footer';
import {logOut} from '../actions/logOut';
import {handleModal} from '../actions/signUp'
import {bindActionCreators} from "redux";


const mapStateToProps = (state) => {
	return {
		access_token: state.Authentication.access_token,
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		logOut: bindActionCreators(logOut, dispatch),
		handleModal: bindActionCreators(handleModal, dispatch)
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(Footer);