import * as constants from "../constants/constants";

const initialState = {
	news: [],
	error: {},
	isLoading: true
}

export default (state = initialState, action) => {
	const { payload } = action;
	switch (action.type) {	
		case constants.SET_NEWS:
			return Object.assign({}, state, {
				...state,
				news: payload.news,
				isLoading: false
			})
		case constants.SET_NEWS_ERROR:
			console.log('error in blogs index')
			return Object.assign({}, state, {
				...state,
				error: '',
				isLoading: false
			})
		default:
			return state;
	}
}
