import React, {Component} from 'react';
import './Header.scss';
import {Link} from 'react-router-dom';
import Logo from '../../assents/img/ffLogo.png'
import LogoWhite from '../../assents/img/ffLogoWhite.png'
import DesktopHeader from "./DesktopHeader"


class Home extends Component {
	state = {
		userMenuCollapse: false,
		hamburgerMenuCollapse: false
	};

	handleUserMenuCollapse = () => {
		if (!this.state.userMenuCollapse) {
			// attach/remove event handler
			document.addEventListener('click', this.handleUserMenuCollapseOutside, false);
		} else {
			document.removeEventListener('click', this.handleUserMenuCollapseOutside, false);
		}
		this.setState({
			userMenuCollapse: !this.state.userMenuCollapse
		});
	};

	handleUserMenuCollapseOutside = (e) => {
		if (this.node.contains(e.target)) {
			return;
		}
		this.handleUserMenuCollapse();
	};

	handleHamburgerMenuCollapse = (e) => {
		this.setState({
			hamburgerMenuCollapse: !this.state.hamburgerMenuCollapse
		});
	};

	disableBtn = (e) => {
		e.preventDefault();
		e.stopPropagation();
	}

	componentWillUnmount() {
		if (this.handleUserMenuCollapse) {
			document.removeEventListener('click', this.handleUserMenuCollapseOutside, false);
		}
	};


	render() {
		const {handleModal, logOut, access_token} = this.props;
		const {hamburgerMenuCollapse, userMenuCollapse} = this.state;
		
		const informationClass = window.location.pathname.match('/stats/information') ? "active" : "";

		return (
			<header>
				<nav className='hidden-md'>
					<Link className='logo' to={hamburgerMenuCollapse ? {javascript: void(0)} : '/'}>
						<img src={Logo} alt="logo"/>
					</Link>
					<div className='navigationIcons'>
						<button id='menu-icon' onClick={this.handleHamburgerMenuCollapse}
						        className={hamburgerMenuCollapse ? 'btn open' : 'btn'}>
							<span style={hamburgerMenuCollapse ? {background: 'white'} : {background: 'black'}}/>
							<span style={hamburgerMenuCollapse ? {background: 'white'} : {background: 'black'}}/>
							<span style={hamburgerMenuCollapse ? {background: 'white'} : {background: 'black'}}/>
							<span style={hamburgerMenuCollapse ? {background: 'white'} : {background: 'black'}}/>
						</button>
					</div>
					<div className={userMenuCollapse && !hamburgerMenuCollapse ? 'show menu' : 'hide menu'}>
						<div className='menuHeader'>
							<div className='menuHeader_tittle'>MITT KONTO</div>
							<button onClick={this.handleUserMenuCollapse} id='menu-icon'
							        className='btn'>
								<span style={{background: 'white'}}/>
							</button>
						</div>
						<ul ref={node => {this.node = node}} onClick={this.handleUserMenuCollapse}>
							<li>
								<Link className={informationClass} to={access_token ? '/stats/information' : '/login'}>PERSONUPPGIFTER</Link>
							</li>
							<li>
								<Link to={{javascript: void(0)}}>NYHETSBREV</Link>
							</li>
							<li>
								<Link to={{javascript: void(0)}}>PREFERENSER</Link>
							</li>
							<li>
								<Link to={{javascript: void(0)}}>INKÖPSLISTOR</Link>
							</li>
							<li>
								<Link to={{javascript: void(0)}}>KÖPHISTORIK</Link>
							</li>
							<li className={access_token ? null : 'hide'}>
								<Link to='/' onClick={() => logOut(access_token)}>LOGGA UT</Link>
							</li>
						</ul>
					</div>
					<div onClick={this.handleHamburgerMenuCollapse}
					     className={hamburgerMenuCollapse ? 'show hamburger' : 'hide hamburger'}>
						<div className="hamburgerLogo">
							<img src={LogoWhite} alt="logo"/>
						</div>
						<ul>
							<li>
								<Link onClick={handleModal} to={{javascript: void(0)}}>BLI EN FOODFIGHTER</Link>
							</li>
							<li>
								<Link to='/blog'>BLOGGEN</Link>
							</li>
							<li>
								<Link to='/about'>OM OSS</Link>
							</li>
							<li>
								<Link to='/news'>NYHETER</Link>
							</li>
							<li>
								<a href="mailto:info@foodfighters.se">KONTAKT</a>
							</li>
						</ul>
					</div>
				</nav>
				<DesktopHeader handleModal={handleModal}/>
			</header>
		);
	}
}

export default Home;
