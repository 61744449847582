import React from 'react';
import {Field, reduxForm, Form} from 'redux-form';
import inputField from './Fields/inputField';
import Integritetspolicy from '../../assents/pdf/Integritetspolicy.pdf'

const validate = values => {
  const errors = {};
  if (!values.first_name) {
    errors.first_name = "Förnamnet kan inte vara tomt"
  }
  if (!values.last_name) {
    errors.last_name = "Efternamnet kan inte vara tomt"
  }
  if (!values.email) {
      errors.email = 'Ogiltig e-postadress'
  }else if (!/^ *[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4} *$/i.test(values.email)) {
      errors.email = 'Ogiltig e-postadress.'
  }
  if (!values.zip_code) {
    errors.zip_code = "Postnummer kan inte vara tomt"
  }else if(!(/^[0-9]{5}$/i.test(values.zip_code) || /^[0-9]{3} [0-9]{2}$/i.test(values.zip_code))) {
    errors.zip_code = 'Felaktigt postnummer'
  }
  
  if (!values.age) {
    errors.age = "Ålder kan inte vara tomt"
  } else if (!/^\d+$/.test(values.age) || values.age > 100) {
    errors.age = "Ange ålder i siffror"
  }
  
  return errors
};


let SignUpForm = props => {
	const {handleSubmit, signUp, invalid} = props;
	return (
		<Form onSubmit={handleSubmit(signUp)} noValidate>
      <div className="form-container row">
        <div className="left-form-block">
          <Field name='first_name' type='text' component={inputField}
            label='Förnamn*'
            placeholder='Förnamn' focus={true}/>
          <Field name='last_name' type='text' component={inputField}
            label='Efternamn*'
            placeholder='Efternamn'/>
          <Field name='email' type='text' component={inputField}
                label='Email *'
                placeholder='Email'
          />
          <Field name='zip_code' type='text' component={inputField}
            label='Postnummer *'
            placeholder='Postnummer'
          />
          <Field name='age' type='number' min="1" max="100" component={inputField}
            label='Ålder *'
            placeholder=' Ålder'
          />
          <div className='explanetion'>
            * = obligatoriskt                           
          </div>
        </div>
        <div className="right-form-block">
          <div className="right-form-block-title">
            Välj alla alternativ du är intresserad av:
          </div>
          <label htmlFor='is_test_period' className='custChbox'>
            Jag vill vara med och utforma framtidens sätt att handla mat under testperioden.
            <Field
              name='is_test_period'
              id='is_test_period'
              component='input'
              type='checkbox'
            />
            <span className='custChbox-mark'/>
          </label>
          <label htmlFor='is_where_i_live' className='custChbox'>
            Jag vill få meddelande om när jag kan använda tjänsten där jag bor.
            <Field
              name='is_where_i_live'
              id='is_where_i_live'
              component='input'
              type='checkbox'
            />
            <span className='custChbox-mark'/>
          </label>
          <label htmlFor='is_received_news' className='custChbox min-h-0'>
            Jag vill få nyhetsbrev.
            <Field
              name='is_received_news'
              id='is_received_news'
              component='input'
              type='checkbox'
            />
            <span className='custChbox-mark'/>
          </label>
          <div className='agreement'>
            Härmed samtycker jag också till att FoodFighters sparar mina uppgifter i enlighet med <a href={Integritetspolicy} target='_blank' rel="noopener noreferrer">integritetspolicyn.</a>
          </div>
          <button className='btn projectButton white-color black-bg'
                  type='submit'
                  disabled={invalid}
          >
            JAG ÄR MED!
            <span className="arrow">3</span>
          </button>
        </div>
      </div>
		</Form>
	);
};


export default reduxForm({
	form: 'SignUpForm',
	validate,
	asyncBlurFields: [],
})(SignUpForm);