import * as constants from "../constants/constants";

const initialState = {
	blogs: [],
	error: '',
	isLoading: true
}

export default (state = initialState, action) => {
	const {payload} = action;
	switch (action.type) {	
		case constants.SET_BLOGS:
			return Object.assign({}, state, {
				...state,
				blogs: payload.blogs,
				isLoading: false
			})
		case constants.SET_BLOG_ERROR:
			console.log('error in blogs index')
			return Object.assign({}, state, {
				...state,
				error: '',
				isLoading: false
			})
		case constants.SEARCH_BLOG:
			return Object.assign({}, state, {
				...state,
				blogs: payload.blogs,
				isLoading: false
			})
		default:
			return state;
	}
}
