import React, {Suspense} from 'react';
import {Redirect, Route} from 'react-router-dom';
import Loader from '../components/Loader/Loader';


export const PrivateRoute = ({component: Component, access_token, ...rest}) => (
	<Route {...rest} render={(props) => {
		return (
			<Suspense fallback={<Loader/>}>
				{access_token ? <Redirect to='/'/> : <Component {...props}/>}
			</Suspense>)
	}}
	/>
);


