import React from 'react';
import { render } from 'react-dom'
import './styles/index.scss';
import './styles/list.scss';
import './styles/show.scss';
import './styles/form.scss';
import './styles/Login.scss';
import App from './containers/App';
import {Provider} from 'react-redux'
import configureStore, { history } from './store/configureStore';
import { ConnectedRouter } from 'connected-react-router'

let store = configureStore();

render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<App history={history}/>
		</ConnectedRouter>
	</Provider>
	, document.getElementById('root')
);
