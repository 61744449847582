import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import Checkout from './Checkout'
import Modal from './Modal'
import Authentication from './Authentication'
import InformationForm from './InformationForm'
import Information from './Information'
import Blog from './Blog'
import News from './News'
import SignUp from './SignUp'
import { connectRouter } from 'connected-react-router'


export default (history) => combineReducers({
	router: connectRouter(history),
	Checkout,
	Modal,
	Information,
	Authentication,
	InformationForm,
	form: formReducer,
	Blog,
	News,
	SignUp
})
