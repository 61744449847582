import React from 'react'

const inputField = ({input, label, type, placeholder, autocomplete, meta: {touched, error, warning}, min, max, focus}) => (
	<div className="group">
		<input {...input} type={type} required autoComplete={autocomplete} min={min} max={max}
		       className={(touched && error && 'validation') || null}  autoFocus={focus}/>
		<span className="highlight"/>
		<label>{label}</label>
		{touched && ((error && <span className="error">{error}</span>) || (warning && <span>{warning}</span>))}
	</div>
);


export default inputField