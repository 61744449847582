import React, {Component} from 'react';
import '../../styles/Login.scss';
import './SignUp.scss';
import SignUpForm from '../Forms/SignUpForm';
import CloseBtn from '../../assents/img/close.png'

export default class SignUp extends Component {
	constructor (props) {
		super(props)
		this.handleModal = this.props.handleModal
		this.toggleModalResults = this.props.toggleModalResults
		this.state = {
			modalStyle: {}
		};
	}


	componentDidMount() {
		setTimeout(() => {
			if(window.innerWidth < 1024){
				let headerHeight = document.getElementById('headerWrapper').offsetHeight;
				this.setState({
					modalStyle: {top: headerHeight + 'px'}
				})
			}
		}, 1000);
	}
	render() {
		const {showModalSignUp, handleModal, closeModal, toggleModalResults, showModalResult, createSignUp} = this.props;
		return (
			<>
				{ showModalSignUp && 
					<div className="overlay-sign-up" onClick={closeModal}></div>
				}
				{ showModalSignUp &&
					<div className="sign-up-modal" style={this.state.modalStyle}>
						<img src={CloseBtn} alt='close' onClick={handleModal} className='close-btn'></img>
						<div className="modal-title green-bg">
							<h1>
								<span className="black-color">jag vill</span>
								<span className="white-color">vara med!</span>
							</h1>
						</div>
						<SignUpForm
							signUp={createSignUp}
							showModalSignUp={showModalSignUp}
						/>
					</div>
				}
				{ showModalResult &&
					<div className="sign-up-modal result-modal" style={this.state.modalStyle}>
						<img src={CloseBtn} alt='close' onClick={toggleModalResults} className='close-btn'></img>
						<div className="modal-title modal-title-results green-bg">
							<h1>
								<span className="white-color">KUL ATT DU ÄR MED! </span>
							</h1>
						</div>
						<div className="description">
							Tillsammans gör vi skillnad! 
						</div>
						<button onClick={toggleModalResults} className='btn btn-result projectButton'>
							STÄNG
						</button>
					</div>
				}
			</>
		);
	}
};


