import * as constants from "../constants/constants";

const initialState = {
	initialValues:{
		household_members: [{}]
	}
};

export default (state = initialState, action) => {
	const {payload} = action;
	switch (action.type) {	
		case constants.SAVE_FAMILYINFO:
			return Object.assign({}, state, {
				...state,	
				initialValues: Object.assign(state.initialValues, payload.data)				
			});
		case constants.GET_USER:
			return Object.assign({}, state, {
				...state,
				initialValues: Object.assign(state.initialValues, payload.data)
			})
		case constants.CLEAR_USER:
			return Object.assign({}, state, {
				...state,
				initialValues: {
					household_members: [{}]
				}
			})		
		default:
			return state;
	}
};