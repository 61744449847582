import React, {lazy, Component, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import './App.scss';
import Header from '../../containers/Header'
import Loader from '../Loader/Loader'
import {PrivateRoute} from '../../helper/PrivateRoute'
import Cookie from '../Cookie/Cookie'
import Footer from '../../containers/Footer'
import SignUp from '../../containers/SignUp'
import ScrollToTop from '../Scroll/ScrollToTop'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-141780256-1');

const ShoppingList = lazy(() => import('../../containers/ShoppingList'));
const Stats = lazy(() => import('../../components/Stats/Stats'));
const Login = lazy(() => import('../../containers/Login'));
const NewsShow = lazy(() => import('../News/Show'));
const BlogShow = lazy(() => import('../Blog/Show'));
const About = lazy(() => import('../../containers/About'));
const Home = lazy(() => import('../../containers/Home'));
const Welcome = lazy(() => import('../Welcome/Welcome'));
const ResetPasswordPage = lazy(() => import('../../containers/ResetPasswordPage'));
const Blog = lazy(() => import('../../containers/Blog'));
const News = lazy(() => import('../../containers/News'));

class App extends Component {
	componentDidMount  = () => ReactGA.pageview(window.location.pathname + window.location.search);
  componentDidUpdate = () => ReactGA.pageview(window.location.pathname + window.location.search);
	render() {
		const {access_token} = this.props;
		return (
			<>
				<div className="main-wrapper backgroundImage">
					<Cookie/>
					<ScrollToTop/>
					<SignUp/>
					<div className="main-container">
						<div className="mobileheader-content-wrapper" id="headerWrapper">
							<Header/>
						</div>
						<Suspense fallback={<Loader/>}>

						
						<Switch>
							<Route exact path='/' render={(props) => (
									<Home {...props}/>
								)}
							/>
							<Route path='/shopping-list' render={(props) => (
									<ShoppingList {...props}/>
								)}
							/>
							<Route path='/stats' render={(props) => (
								
									<Stats {...props}/>
								)}
							/>
							<PrivateRoute path='/login' access_token={access_token} component={Login}/>
							<Route path={`/welcome`} render={(props) => (
								
									<Welcome {...props}/>
								)}
							/>
							<Route path={`/users/reset_password/success`} render={(props) => (
									<ResetPasswordPage {...props}/>
								)}
							/>
							<Route path={`/about`} render={(props) => (
									<About {...props}/>
								)}
							/>
							<Route path={`/blog/`} render={(props) => (
								
									<Blog {...props}/>
								)}
							/>
							<Route path={`/blog-show/:alias`} render={(props) => (
									<BlogShow {...props}/>
								)}
							/>
							<Route path={`/news`} render={(props) => (
									<News {...props}/>
								)}
							/>
							<Route path={`/article/:alias`} render={(props) => (
									<NewsShow {...props}/>
							)}
							/>
						</Switch>
						</Suspense>
					</div>
					<Footer onShowCookie={ () => { console.log('footer call') } }/>
				</div>
			</>
		);
	}
}

export default App;
