import * as constants from '../constants/constants'

const initialState = {
	showModal: false,
	tradeItem: [],
	measurement: [],
};

export default (state = initialState, action) => {
	const {payload} = action;
	switch (action.type) {
		case constants.HANDLE_MODAL:
			//doesn't match
			const tradeItem = [];
			//invalid units/amount
			const measurement = [];
			Object.entries(payload.errorData).forEach(phrase=> {
				if(phrase[1].tradeItem){
					tradeItem.push(phrase[0])
				}
				if(phrase[1].measurement || phrase[1].quantity){
					measurement.push(phrase[0])
				}
			});
			return Object.assign({}, state, {
				...state,
				showModal: !state.showModal,
				tradeItem: tradeItem,
				measurement: measurement,
			});

		default:
			return state;
	}
};