import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { ReactComponent as Megaphone } from '../../assents/img/megaphone.svg';
import Cookies from '../../assents/pdf/Cookies.pdf'
import Integritetspolicy from '../../assents/pdf/Integritetspolicy.pdf'

import { ReactComponent as Facebook } from '../../assents/img/facebook.svg';
import { ReactComponent as Linkedin } from '../../assents/img/linkedin.svg';
import { ReactComponent as Instagram } from '../../assents/img/inst.svg';

class DesktopFooter extends Component {
  render () {
    const { onShowCookie, handleModal } = this.props
    return (
      <div className="main-container">
        <div className="row m-0 desktop-footer__wrapper">
          <div className="col-md-3 p-0">
            <ul className="footer-navigation">
              <li>
                <Link className="footer-link" onClick={handleModal} to={{javascript: void(0)}}>BLI EN FOODFIGHTER</Link>
              </li>
              <li>
                <Link className="footer-link" to='/blog'>BLOGGEN</Link>
              </li>
              <li>
                <Link className="footer-link" to='/about'>OM OSS</Link>
              </li>
              <li>
                <Link className="footer-link" to='/news'>NYHETER</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-2">
            <ul className="footer-navigation">
              <li>
                <a className="footer-link ml-5px" href="mailto:info@foodfighters.se">KONTAKT</a>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <ul>
              <li className='social-wrapper'>
                <span className="footer-link">FöLJ OSS:</span>
                <span className='social'>
                 <a href='https://www.instagram.com/we_are_foodfighters/' target="_blank" rel="noopener noreferrer">
                   <Instagram/>
                  </a>
                  <a href='https://www.facebook.com/foodfighters.se/' target="_blank" rel="noopener noreferrer">
                   <Facebook/>
                  </a>
                  <a href='https://www.linkedin.com/company/40656753' target="_blank" className="linkedin-icon" rel="noopener noreferrer">
                    <Linkedin/>
                  </a>
                </span>
              </li>
            </ul>
          </div>
          <div className="col-md-3 p-0">
            <div className="desktop-footer-logo footer-logo">
              <Megaphone/>
            </div>
          </div>
        </div>
        <div className='info'>
          <div>
            <span onClick={ () => { onShowCookie() } }>
              <a className='policy' href={Cookies} target='_blank' rel='noopener noreferrer'>COOKIES</a>
            </span> 
            <a className='policy' href={Integritetspolicy} target='_blank' rel='noopener noreferrer'>PERSONUPPGIFTER</a>
          </div>
          <div>@ FoodFighters AB 2020</div>
        </div>
      </div>
    )
  }
}

export default DesktopFooter;