import React, {Component} from 'react';
import './Footer.scss';
import MobileFooter from "./MobileFooter"
import DesktopFooter from "./DesktopFooter"

class Footer extends Component {
	render() {
		const { onShowCookie, handleModal } = this.props
		return (
			<footer>
				<div className="mobile-footer hidden-md">
					<MobileFooter handleModal={handleModal} onShowCookie={ () => { onShowCookie()} }/>
				</div>
				<div className="desktop-footer hidden-xs">
					<DesktopFooter handleModal={handleModal} onShowCookie={ () => { onShowCookie() } }/>
				</div>
			</footer>
		);
	}
}

export default Footer;
