import * as constants from '../constants/constants'

const initialState = {
	showModalSignUp: false,
	showModalResult: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case constants.TOGGLE_MODAL:
			return Object.assign({}, state, {
				...state,
				showModalSignUp: !state.showModalSignUp
			});
		case constants.CLOSE_MODAL:
			return Object.assign({}, state, {
				...state,
				showModalSignUp: false
			});	
		case constants.TOGGLE_MODAL_RESULTS:
			return Object.assign({}, state, {
				...state,
				showModalResult: !state.showModalResult
			});

		default:
			return state;
	}
};