import * as constants from '../constants/constants'

const initialState = {
	products: {},
};

export default (state = initialState, action) => {
	const {payload} = action;
	switch (action.type) {
		case constants.GET_PRODUCTS:
			return Object.assign({}, state, {
				...state,
				products: {
					cheapest: payload.products.cheapest,
					healthiest: payload.products.healthiest
				},
			});
		case constants.SORT_BY_ENVIRONMENT:
			return Object.assign({}, state, {
				...state,
				[payload]: state.products[payload].sort((a, b) => (b.scores.environment) - (a.scores.environment)),
			});
		case constants.SORT_BY_HEALTH:
			return Object.assign({}, state, {
				...state,
				[payload]: state.products[payload].sort((a, b) => (b.scores.health) - (a.scores.health)),
			});
		case constants.SORT_BY_WORKING_CONDITIONS:
			return Object.assign({}, state, {
				...state,
				[payload]: state.products[payload].sort((a, b) => (b.scores.working_conditions) - (a.scores.working_conditions)),
			});
		case constants.SORT_BY_PRICE:
			return Object.assign({}, state, {
				...state,
				[payload]: state.products[payload].sort((a, b) => (b.scores.price) - (a.scores.price)),
			});
		case constants.SORT_BY_TOTAL_SCORE:
			return Object.assign({}, state, {
				...state,
				[payload]: state.products[payload].sort((a, b) => (b.scores.total_score) - (a.scores.total_score)),
			});
		case constants.SORT_BY_DEFAULT:
			return Object.assign({}, state, {
				...state,
				[payload]: state.products[payload].sort(() => 0.5 - Math.random()),
			});
		default:
			return state;
	}
};