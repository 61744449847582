import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import monitorReducersEnhancer from '../enhancers/monitorReducer'
import rootReducer from '../reducers/index'

export default function configureStore(preloadedState) {
	const middlewares = [
		thunkMiddleware,
		routerMiddleware(history)
	];
	const middlewareEnhancer = applyMiddleware(...middlewares);
	
	const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
	const composedEnhancers = compose(...enhancers);

	const store = createStore(rootReducer(history), preloadedState, composedEnhancers);

	return store
}

export const history = createBrowserHistory();