import * as constants from "../constants/constants";

const initialState = {
	genders: []
};

export default (state = initialState, action) => {
  const {payload} = action;
	switch (action.type) {	
		case constants.GET_GENDERS:
			return Object.assign({}, state, {
				...state,
        genders: payload.data
			});		
		default:
			return state;
	}
};