import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Logo from '../../assents/img/ffLogo.png'

class DesktopHeader extends Component {
  render () {
    const {handleModal} = this.props
    return (
      <>
        <div className="hidden-xs desk-header row m-0">
          <div className="col-sm-5 desk-header_left">
            <Link className="logoLink" to={'/'}>
              <div className="desktop-header-logo">
                <img src={Logo} alt="logo"/>
              </div>
            </Link>
          </div>
          <div className="col-sm-7 desk-header_right">
            <div className="header-link-wrapper">
              <Link className="header-link" onClick={handleModal} to={{javascript: void(0)}}>BLI EN FOODFIGHTER</Link>
              <span className="header-link-delimiter">F</span>
              <Link className="header-link" to='/blog'>BLOGGEN</Link>
              <span className="header-link-delimiter">F</span>
              <Link className="header-link" to='/about'>OM OSS</Link>
              <span className="header-link-delimiter">F</span>
              <Link className="header-link" to={{javascript: void(0)}}>LOGGA IN</Link>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default DesktopHeader;