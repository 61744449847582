import axiosCall from '../helper/index';
import * as constants from "../constants/constants";


function clearToken() {
	return {
		type: constants.LOGOUT
	}
}

function clearUserInfo() {
	return {
		type: constants.CLEAR_USER
	}
}

export const logOut = (token) => {
	let headers={Authorization: token}
	return (dispatch) => {
		axiosCall.post('logout', {}, { 
			headers: headers
		}
		)
			.then(response => {
				dispatch(clearToken())
				dispatch(clearUserInfo())
			})
			.catch(error => {
			})
	}
};
